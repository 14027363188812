/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
// Variables

$bodyfont: 'Poppins', sans-serif;
$headingfont: 'Poppins', sans-serif;

@use '@angular/material' as mat;
@import '@angular/material/theming';

$themecolor: #006595;
$themecolor-alt: #006595;

/*Material Theme Colors*/

// Palette generator http://mcg.mbitson.com/#!?mcgpalette

$primary-palette: (
        50 : #e0edf2,
        100 : #b3d1df,
        200 : #80b2ca,
        300 : #4d93b5,
        400 : #267ca5,
        500 : #006595,
        600 : #005d8d,
        700 : #005382,
        800 : #004978,
        900 : #003767,
        A100 : #96c7ff,
        A200 : #63abff,
        A400 : #308fff,
        A700 : #1782ff,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #ffffff,
                A700 : #ffffff,
        )
);

$secondary-palette: (
        50 : #e0edf2,
        100 : #b3d1df,
        200 : #80b2ca,
        300 : #4d93b5,
        400 : #267ca5,
        500 : #006595,
        600 : #005d8d,
        700 : #005382,
        800 : #004978,
        900 : #003767,
        A100 : #96c7ff,
        A200 : #63abff,
        A400 : #308fff,
        A700 : #1782ff,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #ffffff,
                A700 : #ffffff,
        )
);

$warn-palette: (
        50 : #ffe7e7,
        100 : #fec4c4,
        200 : #fd9d9d,
        300 : #fc7575,
        400 : #fc5858,
        500 : #fb3a3a,
        600 : #fa3434,
        700 : #fa2c2c,
        800 : #f92525,
        900 : #f81818,
        A100 : #ffffff,
        A200 : #fff6f6,
        A400 : #ffc3c3,
        A700 : #ffaaaa,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$primary: mat.define-palette($primary-palette, 600);
$accent: mat.define-palette($secondary-palette, 500);
$warn: mat.define-palette($warn-palette, 500);
$theme: mat.define-light-theme($primary, $accent, $warn);
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);

// Default Theme
@include mat.all-legacy-component-themes($theme);

// Dark Theme

/*Theme Colors*/

$sidebar: #fff;
$bodycolor: #eef5f9;
$headingtext: #455a64;
$bodytext: #67757c;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4;

$font-16: 16px;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #00ccfd;
$warning: #ffb22b;
$primary: #7460ee;
$info: #0087fd;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;

/*Light colors*/
$light-danger: #f9e7eb;

/*Normal Color*/
$white: #ffffff;
$red: #fb3a3a;
$purple: #7460ee;
$blue: #02bec9;
$megna: #00897b;

/*Extra Variable*/
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$radius: 4px;

$page-wrapper-boxed-width: 95vw;
$page-wrapper-mobile-boxed-width: 100%;


