/* You can add global styles to this file, and also import other style files */

/*******************
 Login register
*******************/
.blank-container {
  height: 100vh;
  display: flex;
  background-size: cover;
  align-items: center;
  background-image: url(/assets/images/background/login-register.jpg);
  box-shadow: inset 0 0 0 1000px rgb(1 0 61 / 14%);
  background-position: center center;

  .mat-card {
    margin: 0 15px;
  }

  .mat-form-field {
    width: 100%;
  }

  .blank-container-box {
    max-width: 400px;
    min-width: 280px;
    margin: auto;
    vertical-align: middle;

    .password-cntr {

      .mat-form-field-infix {

        display: flex !important;

      }

      .material-icons {

        vertical-align: text-bottom;

      }

    }
  }

  .support-text {
    margin-top: -15px;
    display: block;
    padding-bottom: 15px;
  }
}

// CUSTOM STYLING
// TODO: verificare come disabilitare queste regole all'interno della ct-datatable
.control-cntr {

  .mat-form-field:not(.page-size-select-cntr) {

    .mat-form-field-wrapper {

      .mat-form-field-flex {

        border-radius: 8px 8px 0 0 !important;
        padding: .5em .5em 0 .5em;
        box-shadow: 0 2px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

      }

    }

    .mat-form-field-appearance-fill .mat-form-field-underline::before {

      background-color: rgba(154, 154, 154, 0.5);

    }

  }

  .mat-checkbox {

    .mat-checkbox-inner-container {

      height: 24px;
      width: 24px;

      .mat-checkbox-frame {

        box-shadow: 0px 2px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        background-color: rgba(0, 0, 0, 0.04);
        border: 0px;

      }

    }

  }

}

body {

  font-weight: 200 !important;

  .ct-button {
    border-radius: 2px !important;
  }

}


ct-search-filters {

  .mat-expansion-panel-header-title, .mat-expansion-panel-header-description {

    font-size: 22px;
    color: #006595;

  }

  .filters-cntr {

    .mat-expansion-panel-header, .mat-expansion-panel-body, .mat-action-row {

      padding: 30px 50px;

    }

    .mat-action-row {

      border-top: none;
      justify-content: end !important;

    }

  }

}

.sidebar-toggle {

  .mat-icon {

    height: 32px;
    width: 32px;
    line-height: 32px !important;
    font-size: 32px;
    font-weight: bold;

  }

  margin-right: 50px !important;

}

.user-profile-cntr {

  color: #006595;
  padding-top: 5px;
  font-weight: lighter;
  margin-right: 25px;

}


.mat-toolbar {

  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.5) !important;

}


.app-sidebar {

  .separator {

    color: #006595 !important;
    font-size: 18px !important;
    font-weight: 400 !important;

  }

  .mat-list-item {

    .mat-list-item-content {

      a {

        border-radius: 0px !important;
        color: #006595 !important;

        .mat-icon {

          color: #006595 !important;

        }

      }

      a[appaccordiontoggle] {

        display: flex !important;

      }

    }

    &.selected {

      .mat-list-item-content {

        a[appaccordiontoggle] {

          color: #ffffff !important;

          .mat-icon {

            color: #ffffff !important;

          }

        }

      }

    }

  }

}

.minisidebar {

  .separator {

    span {

      font-size: 12px !important;
    }

  }

}

.specific-contract-dialog-cntr, .ct-model-on-fly-create-container, .location-create-container {

  .mat-dialog-container {

    .datatable-body {
      width: 100% !important;
    }

    width: 80vw !important;
    margin: auto;
    padding: 0px !important;

  }

  .mat-card {

    margin: 0px !important;

  }

}

.text-center {

  text-align: center;

}

.customer-create-container {

  .mat-dialog-container {

    padding: 0px;

    .mat-card {

      margin: 0px;

    }

  }

}


.ct-model-on-fly-create-container {

  &.ContractLookup {

    .control-cntr:nth-child(1), .control-cntr:nth-child(2) {

      display: none;

    }

  }

}


.cursor {

  cursor: pointer;

}

ct-datatable-cell {

  p {

    margin-block-start: 2.5px;

  }

}

ct-datatable-action {

  .mat-icon-button {

    height: 30px;
    width: 30px;
    line-height: 30px;

    .material-icons {

      font-size: 20px;

    }



  }
}

ct-datatable {

  .datatable-scroll {

    max-height: 600px;
    overflow-y: auto !important;

  }

  .bullet-state-container {

    margin-left: 17.5px;

    span {

      display: none;

    }

  }

}
ct-datatable-action.no-data {
  button {
    color: #bdbfba !important;
    cursor: not-allowed;
  }
}
